import React, { useState, useEffect } from 'react'
import { Link } from 'react-scroll'
import { getStrapCollections } from '../data/getStrapCollections'

const Navbar = ({ theme, changeTheme }) => {
  const [ show, setShow ] = useState(true)
  const [ data, setData ] = useState([])
  const collectionData = 'navbar-sections?populate=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapCollections(collectionData)
      setData(data)
    }
    fetchData()
  },[])

  const handleTheme = () => {
    changeTheme()
  }

  const handleIsShow = () => {
    setShow(!show)
  }

  window.addEventListener('load', () => {
    if(window.innerWidth > 767){
      setShow({ show: true })
    }else{
      setShow({ show: false })
    }
  })

  return (
    <nav className="bg-white w-full border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900">
      {data?.map(({ navbarLogo }, index) =>
        <div key={index} className="container flex flex-wrap justify-between flex-row items-center mx-auto">
          <Link to='home' className="flex items-center w-[4rem]">
            <img className="self-center ml-4 lg:ml-0" width="auto" alt='logo' src={navbarLogo} />
          </Link>
          <div className="flex md:order-2">
            <button type='button' onClick={() => handleTheme()} className='inline-flex items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600'>
              {theme === true && (<svg className='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path d='M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z'> </path></svg>)}
              {theme === false && (<svg className='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path d='M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z' fillRule='evenodd' clipRule='evenodd'> </path></svg>)}
            </button>
            <button onClick={() => handleIsShow()} data-collapse-toggle="navbar-cta" type="button" className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-cta" aria-expanded="false">
              <span className="sr-only">Open main menu</span>
              <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
            </button>
          </div>
          <div className={show ? "hidden justify-between items-center w-full md:flex md:w-auto md:order-1" : "block justify-between items-center w-full md:flex md:w-auto md:order-1"} id="navbar-cta">
            <ul className="flex flex-col p-4 mt-4 bg-gray-50 rounded-lg border border-gray-100 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <Link to='home' smooth spy className="cursor-pointer block py-2 pr-4 pl-3 mb-4 md:mb-0 text-gray-700  rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white" aria-current="page">Home</Link>
              </li>
              <li>
                <Link to='about' smooth spy className="cursor-pointer block py-2 pr-4 pl-3 mb-4 md:mb-0 text-gray-700  rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white" aria-current="page">About</Link>
              </li>
              <li>
                <Link to='services' smooth spy className="cursor-pointer block py-2 pr-4 pl-3 mb-4 md:mb-0 text-gray-700 rounded dark:text-white hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Services</Link>
              </li>
              <li>
                <Link to='contact' smooth spy className="cursor-pointer block py-2 pr-4 pl-3 mb-4 md:mb-0 text-gray-700 rounded dark:text-white hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </nav>
  )
}

export default Navbar
